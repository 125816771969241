export function queryMatchesVistorId (query: string): boolean {
  if (query.length >= 1) {
    const startWith = query.substring(0, 1)
    if (startWith === '6') return true
  }

  return false
}

export function vistorIdSearch (query: string, onChangeFn: any, requestType: string): any {
  if (query.length !== 9) {
    return setIncompleteCSGoldItem()
  } else {
    return setCompleteVistorIdSearch(query, onChangeFn, requestType)
  }
}

function setIncompleteCSGoldItem (): any {
  const incompleteSearchEntry = {
    icon: 'user',
    text: 'Incomplete visitor ID',
    secondaryText: '',
    tertiaryText: 'Please enter all 9 digits',
    quaternaryText: '',
    onClick: () => undefined
  }

  return {
    cardholders: [incompleteSearchEntry],
    isBusy: false
  }
}

function setCompleteVistorIdSearch (visitorId: string, onChangeFn: any, requestType: string): {cardholders: any[], isBusy: boolean} {
  const completeSearchEntry = {
    icon: 'user',
    text: 'Visitor ID',
    secondaryText: visitorId,
    tertiaryText: 'Send ' + requestType + ' request for ID',
    quaternaryText: visitorId,
    onClick: () => onChangeFn(visitorId)
  }

  return {
    cardholders: [completeSearchEntry],
    isBusy: false
  }
}
