import { AuthProvider as Auth } from '@mit/aws-react'
import { FederatedSignInOptionsCustom } from '@aws-amplify/auth/lib/types'
import appConfig from '../app-config-global'

// Amplify.configure(appConfig.amplify);

class AuthProvider {
  setCookie (name: string, value: string, days: number) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  getCookie (name: string) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  deleteCookie (name: string) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }

  constructor () {
    // Auth.currentAuthenticatedUser().then((res) =>
    // {
    //     let cookieExist = this.getCookie("authmember");
    //     if (cookieExist)
    //     {
    //         this.deleteCookie("authmember");
    //         window.location.href = cookieExist;
    //     }
    // }).catch((err) =>
    // {
    //     this.setCookie("authmember", document.location.pathname, 1);
    // });
  }

  getToken = async (): Promise<any> => {
    // return new Promise((resolve, reject) =>
    // {
    //     Auth.currentAuthenticatedUser().then((res) =>
    //     {
    //         //console.log("logged in", res);
    //         resolve(res.getSignInUserSession().accessToken.jwtToken)
    //     }).catch((err) =>
    //     {
    //         console.log("not logged in", err);
    //         Auth.federatedSignIn({ customProvider: appconfig.signInProvider } as FederatedSignInOptionsCustom).then((res1) =>
    //         {
    //             console.log("login success", res1);
    //         }).catch((err1) =>
    //         {
    //             console.log("login err", err1);
    //         });
    //     });
    // });

    const authorizationToken = await new Auth(Object.assign(appConfig)).getToken()
    return authorizationToken
  }

  logout = async (): Promise<any> => {
    // await Auth.signOut();
    console.log('AuthProviderLocal.logout()')
    await new Auth(Object.assign(appConfig)).signOut()
  }
}

export default AuthProvider
