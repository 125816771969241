import BaseController from './BaseController'
import { ProfileModel } from './models/ProfileModel'
import apiConfig from './api-config'
import { SearchModel } from './models/SearchModel'
import StringProvider from '../services/StringProvider'
import { queryMatchesVistorId } from '../common/VisitorIdSupport'

class HomeController extends BaseController {
  //GET
  getProfile = async (id?: string): Promise<ProfileModel> => {
    return await this.fetchData(this.apiPath, '/' + apiConfig[this.configKey].paths.apiPathDigitalId + `/profile${id ? '/' + id : ''}`)
  }

  getPeopleSearchProfile = async (id?: string): Promise<any> => {
    if (id === undefined) {
      return null
    }

    let peopleSearchResponse: PoepleSearchPerson[] = await this.fetchData(this.apiPath, '/' + apiConfig[this.configKey].paths.apiPathPhysicalSecurity + '/people/search', {
      query: id
    })

    const mitIdExpression = /^\d{9}$/
    const kerbExpression = /[a-zA-Z]/

    let person: PoepleSearchPerson | null = null

    for (var searchPerson of peopleSearchResponse) {
      // Check if id passed is a mitId
      if (mitIdExpression.test(id)) {
        if (searchPerson.mitId === id) {
          person = searchPerson

          // No need to loop further if person is found
          break
        }
      }

      // Check if id passed is a kerb
      if (kerbExpression.test(id)) {
        // User kerb might not exist
        if (searchPerson.krbName === undefined) {
          continue
        }

        if (searchPerson.krbName === id) {
          person = searchPerson

          // No need to loop further if person is found
          break
        }
      }
    }

    // No results found
    if (person === null) return null

    let userEmail = null
    let affiliation = null

    if (person.mitEmail !== undefined) {
      userEmail = person.mitEmail
    } else if (person.nonMitEmail !== undefined) {
      userEmail = person.nonMitEmail
    } else if (person.alumniEmail !== undefined) {
      userEmail = person.alumniEmail
    }

    const isAffiliate = person?.affiliate ?? false
    const isStudent = person?.student ?? false
    const isStaff = person?.staff ?? false
    const isAlumni = person?.alumni ?? false
    const isvisitor = person?.guest ?? false

    if (isStudent) {
      affiliation = 'student'
    } else if (isAffiliate) {
      affiliation = 'affiliate'
    } else if (isStaff) {
      affiliation = 'staff'
    } else if (isAlumni) {
      affiliation = 'alumni'
    } else if (isvisitor) {
      affiliation = 'visitor'
    }

    // Check if MIT ID is visitor
    if (queryMatchesVistorId(person.mitId)) {
      affiliation = "Visitor"
    }

    let mappedPerson = {
      affiliation: affiliation,
      department: person.department,
      first_name: person.firstName,
      last_name: person.lastName,
      kerberos: person.krbName,
      kerberos_id: person.krbName,
      mitid: person.mitId,
      mit_id: person.mitId,
      email: person.email
    }

    return mappedPerson
  }

  getCsGoldProfile = async (id: string): Promise<any> => {
    return await this.fetchDataAndStatus(this.hostname, '/' + apiConfig[this.configKey].paths.apiPathPhysicalSecurity + '/people/profiles/csgold/' + id)
  }

  getFeatures = async (): Promise<{ featureFlags: string[] }> => {
    return await this.fetchData(this.apiPath + '/' + apiConfig[this.configKey].paths.apiPathAtlasUI, `/features/physical-security`)
  }

  getPicture = async (): Promise<any> => {
    return await this.fetchPicture(this.apiPath, '/' + apiConfig[this.configKey].paths.apiPathDigitalId + '/picture')
  }

  getPictureByKerb = async (kerb: string): Promise<any> => {
    return await this.fetchPicture(this.apiPath, '/' + apiConfig[this.configKey].paths.apiPathDigitalId + '/picture/' + kerb)
  }

  geCSGoldPicture = async (mitId: string): Promise<any> => {
    return await this.fetchPicture(this.apiPath + '/' + apiConfig[this.configKey].paths.apiPathPhysicalSecurity, '/people/picture', { cardholder_id: mitId })
  }

  getPeopleSearch = async (nameOrId: string): Promise<PoepleSearchPerson[]> => {
    return await this.AbortablefetchData(this.apiPath, '/' + apiConfig[this.configKey].paths.apiPathPhysicalSecurity + '/people/search', { query: nameOrId })
  }

  getSearch = async (nameOrId: string): Promise<SearchModel[]> => {
    return await this.AbortablefetchData(this.apiPath, '/' + apiConfig[this.configKey].paths.apiPathDigitalId + '/search', { q: nameOrId })
  }

  feedback = async (body: any): Promise<any> => {
    try {
      const response = await fetch(this.apiPath + '/feedback-v1/feedback ', await this.requestHeaderPost(body))

      if (response.status === 200) {
        const data = await response.json()

        return data
      } else {
        return response.status
      }
    } catch (e) {
      console.log(e)
      return null
    }
  }

  initialize = async (): Promise<any> => {
    try {
      const response = await fetch(this.baseUrl + '/initialize', await this.requestHeaderGet())
      if (response.status === 200) {
        const data = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (e) {
      return null
    }
  }
}

export default HomeController
