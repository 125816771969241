import React, { FC } from 'react'
// import History from './common/History'
import ReactDOM from 'react-dom'
// import Home from './ts/Home'
import { Provider } from 'react-redux'
import { store } from '../src/ts/redux/store/store'
import { BrowserRouter, Router, Route, Switch } from 'react-router-dom'
import { Initialization } from '@mit/aws-react'
import { useAppInitializer } from './services/initializer'
import appConfig from './app-config-global'
import Logout from './ts/Logout'

export const globalStore = store

const WrappedOKTAApp: FC = () => {
  const { initializeApp } = useAppInitializer()

  const AppLazyLoad = React.lazy(
    async () =>
      await import('./App').then(module => ({
        default: module.App
      }))
  )

  return (
    <React.StrictMode>
      <Switch>
        <Route exact path='/logout' component={Logout} />
        <Initialization
          App={AppLazyLoad}
          appInitializer={initializeApp}
          appConfigData={appConfig}
          loaderProps={{
            contactEmail: 'help@mit.edu',
            name: 'MIT Physical Security',
            isLoading: true,
            type: 'Default'
          }}
        />
      </Switch>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={globalStore}>
      <WrappedOKTAApp />
    </Provider>{' '}
  </BrowserRouter>,
  document.getElementById('root')
)
