const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "atlasFeatureAppId": "physical-security#schedules",
    "paths": {
      "apiPathPhysicalSecurity": "physical-security-v1",
      "apiPathDigitalId": "digital-id-v1",
      "apiPathAtlasUI": "atlas-ui-v1",
      "websocketUrl": "wss://wss-api.mit.edu/notification-v1"
    }
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "atlasFeatureAppId": "physical-security#schedules",
    "paths": {
      "apiPathPhysicalSecurity": "physical-security/v1",
      "apiPathDigitalId": "digital-id/v1",
      "apiPathAtlasUI": "atlas-ui/v1",
      "websocketUrl": "wss://global.wss-api.mit.edu/notification-v1"
    }
  }
};
export default apiConfigs;