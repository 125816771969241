import { combineReducers } from 'redux'
import clearance from './clearance'
import door from './door'
import app from './app'
import notification from './notification'

export const appReducer = combineReducers({
  app,
  clearance,
  door,
  notification
})
